.connectionStatus {
	position: fixed;
	right: 10px;
	bottom: 10px;
	padding: 10px 10px;
	border-radius: 10px;
	background-color: #ffffffcc;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;

	&:hover {
		background-color: #ffffff;
	}

	&__device {
		width: 20px;
		margin: 0 5px;
	}
	&__icon {
		max-width: 15px;
	}
}
