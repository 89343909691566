$space: 40px;
$space-xsm: 10px;

.lit-audio {
	background: transparent;
	border: none;
	$audioSize: 58px;
	width: $audioSize;
	height: $audioSize;
	vertical-align: middle;

	$this: &;

	&__progress {
		stroke: #3629b5;
		stroke-dasharray: 230px;
		stroke-dashoffset: 0;
		opacity: 0;
	}

	&:disabled {
		cursor: not-allowed;
	}

	&__icon {
		$iconSize: 8px;
		position: absolute;
		border: $iconSize solid transparent;
		border-left: ($iconSize * 1.4) solid #fff;
		margin-left: ($iconSize * 1.4 / 2);
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	+ .lit-input__field {
		$audioMargin: $space-xsm;

		display: inline-block;
		vertical-align: middle;
		margin-left: $audioMargin;
		width: 75%;
		max-width: 350px;
		padding: 10px;
		font-size: 20px;
	}

	&.inline {
		$audioSize: $space;
		width: $audioSize;
		height: $audioSize;
		margin-right: $space-xsm;

		#{$this} {
			&__icon {
				$iconSize: 5px;
				border: $iconSize solid transparent;
				border-left: ($iconSize * 1.4) solid #fff;
				margin-left: ($iconSize * 1.4 / 2);
			}
		}
	}

	&.is-playing {
		cursor: default;

		#{$this} {
			&__progress {
				opacity: 1;
			}

			&__icon {
				opacity: 0.4;
			}
		}
	}

	// @include transitions {
	// 	&__icon {
	// 		transition: opacity 0.12s;
	// 	}

	// 	&__outline {
	// 		transition: stroke 0.12s;
	// 	}

	// 	&:hover {
	// 		#{$this} {
	// 			&__icon {
	// 				opacity: 0.8;
	// 			}

	// 			&__outline {
	// 				stroke: rgba(0, 0, 0, 0.2);
	// 			}
	// 		}
	// 	}

	// 	&.is-playing {
	// 		&:hover {
	// 			#{$this} {
	// 				&__icon {
	// 					transition: none;
	// 					opacity: 0.4;
	// 				}

	// 				&__outline {
	// 					transition: none;
	// 					stroke: rgba(0, 0, 0, 0.1);
	// 					opacity: 1;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	&:active {
		#{$this} {
			&__icon {
				transition: none;
				opacity: 0.7;
			}

			&__outline {
				transition: none;
				opacity: 0.7;
			}
		}
	}
}
