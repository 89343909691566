.LetterKnowledge {
	display: flex;
	flex-direction: column;
	align-items: center;

	.letter {
		margin-top: 100px;
		background-color: #0e1e89;
		padding: 20px 40px;
		min-width: 100px;
		font-size: 120px;
		border-radius: 15px;
	}

	.row {
		display: flex;
		font-size: 45px;
		background-color: #0e1e89;
		border-radius: 10px;
		padding: 10px;

		&.previous,
		&.next {
			border-radius: 0px;
			padding: 0px;
			background-color: transparent;
			color: #ccc;
			font-size: 22px;
			filter: brightness(0.9);

			.item {
				padding: 10px;
			}
		}

		.item {
			text-transform: uppercase;
			font-weight: bold;
			padding-left: 20px;
			padding-right: 20px;
			margin: 0;
			&.active {
				// background-color: #1b2e79;
			}
		}
	}
}
