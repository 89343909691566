.SelectRole {
	text-align: center;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	.SelectRoleContainer {
		padding-bottom: 20%;

		form {
			display: inline-block;

			button:not(:first-child) {
				margin-left: 2;
			}
		}
	}

	.title {
		margin: 0;
		letter-spacing: 2px;
		&__logo{
			margin-top: 10px;
		}
	}

	&__heading {
		margin-top: 20px;
		margin-bottom: 0;
	}

	&__label {
		text-align: center;
		label {
			font-size: 23px;
		}
	}

	&__input {
		min-width: 230px;
		text-transform: uppercase;
	}
	&__pinInput {
		min-width: 80px;
		text-align: center;
		color: blue;
		font-size: 18px;
		background-color: rgb(212, 209, 209);

	}
	&__pinInput:focus {
		background-color: rgb(242, 248, 250);
	  }

	&__submit-button {
		margin-top: 20px;
		min-width: 20%;
		
	}

	&__select-button:not(:first-child) {
		margin-left: 10px;
	}
}
.lit-thirdPCsupport {
	margin: auto;
	text-align: left;
	color: red;
	width: 70%;
	font-size: 18px;
}
.lit-thrid-party-settings {
	margin: auto;
	padding: 8px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
}
