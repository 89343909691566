.AdminReadyButton {
	&__button {
		display: inline-block;
		font-size: 16px;
		padding: 10px 20px;
		text-transform: uppercase;
		text-align: center;
		background-color: #46b6e5;
		border-radius: 5px;
	}

	&__label {
		display: inline-block;
	}
}
