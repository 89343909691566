.subtest-complete-notice {
	&__left0{
	z-index:2000;
    position: sticky;
	top:20;
	font-size: 15px;
    
	border-left: solid 2px rgb(125, 248, 2);
	border-bottom: solid 2px rgb(125, 248, 2);
	border-bottom-right-radius: 5px;
	padding-left: 3px;
	color: rgb(125, 248, 2);
    justify-content: left;
	align-items: left;
		margin-left: 1%;
	}
	&__left1{
		z-index:2000;
		position: sticky;
		top:20;
		font-size: 15px;
		
		border-left: solid 2px rgb(125, 248, 2);
		border-bottom: solid 2px rgb(125, 248, 2);
		border-bottom-right-radius: 5px;
		padding-left: 3px;
		color: rgb(125, 248, 2);
		justify-content: left;
		align-items: left;
	
		margin-left: 25.5%;
	}
	&__left2{
		z-index:2000;
		position: sticky;
		top:20;
		font-size: 15px;
		
		border-left: solid 2px rgb(125, 248, 2);
		border-bottom: solid 2px rgb(125, 248, 2);
		border-bottom-right-radius: 5px;
		padding-left: 3px;
		color: rgb(125, 248, 2);
		justify-content: left;
		align-items: left;
		margin-left: 50%;
	}
	&__left3{
		z-index:2000;
		position: sticky;
		top:20;
		font-size: 15px;
		
		border-left: solid 2px rgb(125, 248, 2);
		border-bottom: solid 2px rgb(125, 248, 2);
		border-bottom-right-radius: 5px;
		padding-left: 3px;
		color: rgb(125, 248, 2);
		justify-content: left;
		align-items: left;
		margin-left: 74.5%;
	}

}