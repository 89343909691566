.lit-diktat {
	margin-top: 20px;

	.lit-input {
		&--disabled {
			opacity: 0.5;
		}
	}
}

.WordDictation {
	.lit-intro {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
