.TestUserIntro {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
}

.TestUser {
	display: flex;
	flex-direction: column;
	padding: 20px;
	flex: 1;

	.testKey {
		margin: 0;
	}

	.test {
		text-align: center;
	}

	.lit-page-title {
		text-align: center;
	}
}
