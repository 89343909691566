.PhonemeIsolation {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex-direction: column;

	p {
		max-width: 70%;
	}

	.phoneme-options {
		display: flex;
		flex-wrap: wrap;
	}
	.phoneme-option {
		min-width: 200px;
		flex-basis: 50%;
		// padding: 40px;
		box-sizing: border-box;
		display: flex;
		border: solid 1px black;
		justify-content: center;
		align-items: center;
		text-align: center;
		width: 200px;
		height: 200px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		background-color: white;
	}
}
