@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
body {
  color: white;
  font-size: 25px;
  background-color: #46b6e5;
  background: -webkit-gradient(linear, left top, right bottom, from(#228edb), to(#692bbc));
  background: linear-gradient(to bottom right, #228edb, #692bbc);
  background-attachment: fixed;
  font-family: 'Lato', sans-serif;
  min-height: 100vh;
  padding: 0;
  margin: 0; }

button {
  font-size: 20px;
  border-radius: 5px;
  background-color: #46b6e5;
  color: white;
  border: none;
  padding: 10px 20px; }

button:not(:first-child) {
  margin-left: 10px; }

button:disabled {
  background-color: #ccc;
  color: #555; }

input {
  font-size: 20px;
  padding: 10px; }

#root {
  min-height: 100vh;
  flex: 1 1; }

.lit-circle {
  width: 90px;
  height: 90px;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.lit-circle__interactive,
.lit-circle__static {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.lit-circle__static {
  border-radius: 50%;
  border: 6px solid;
  width: 70px;
  height: 70px;
  margin: 4px; }

.lit-circle__text {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin: 0;
  font-weight: bold;
  font-size: 1.4em;
  cursor: default; }

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .App p {
    max-width: 800px; }
  .App p.warning {
    margin-bottom: 5px;
    margin-top: 5px;
    color: #ffdf4e;
    font-size: 18px; }
  .App label {
    display: block;
    font-size: 18px; }
    .App label input[type='text'],
    .App label input[type='number'],
    .App label input[type='date'],
    .App label select {
      display: block;
      margin-top: 5px;
      margin-bottom: 20px; }
    .App label.inline {
      display: inline-block;
      margin-right: 5px; }
  .App select {
    border: solid 1px #aaa;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .App input {
    border: solid 1px #aaa;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 10px; }
  .App .checkbox-group-title {
    font-size: 20px;
    margin-top: 20px; }
  .App .info-box {
    background-color: #ffffff;
    padding: 20px;
    color: #141414;
    font-size: 18px;
    border-radius: 10px;
    display: inline-block; }
    .App .info-box__title {
      margin-top: 0px;
      margin-bottom: 5px;
      font-size: 20px;
      font-weight: bold; }
    .App .info-box__hint {
      font-size: 18px;
      font-style: italic;
      color: #777;
      margin: 0; }
    .App .info-box ol {
      padding-left: 20px;
      margin-bottom: 0; }
  .App .center {
    text-align: center; }

.AdminReadyButton__button {
  display: inline-block;
  font-size: 16px;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  background-color: #46b6e5;
  border-radius: 5px; }

.AdminReadyButton__label {
  display: inline-block; }

.lit-countdown {
  -webkit-animation: fade 0.8s;
          animation: fade 0.8s; }
  .lit-countdown__progress {
    stroke: red;
    stroke-dasharray: 230px;
    stroke-dashoffset: 0; }
  .lit-countdown .lit-circle__text {
    font-size: 0.6em;
    margin: 0;
    font-weight: bold; }

.lit-audio {
  background: transparent;
  border: none;
  width: 58px;
  height: 58px;
  vertical-align: middle; }
  .lit-audio__progress {
    stroke: #3629b5;
    stroke-dasharray: 230px;
    stroke-dashoffset: 0;
    opacity: 0; }
  .lit-audio:disabled {
    cursor: not-allowed; }
  .lit-audio__icon {
    position: absolute;
    border: 8px solid transparent;
    border-left: 11.2px solid #fff;
    margin-left: 5.6px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .lit-audio + .lit-input__field {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    width: 75%;
    max-width: 350px;
    padding: 10px;
    font-size: 20px; }
  .lit-audio.inline {
    width: 40px;
    height: 40px;
    margin-right: 10px; }
    .lit-audio.inline .lit-audio__icon {
      border: 5px solid transparent;
      border-left: 7px solid #fff;
      margin-left: 3.5px; }
  .lit-audio.is-playing {
    cursor: default; }
    .lit-audio.is-playing .lit-audio__progress {
      opacity: 1; }
    .lit-audio.is-playing .lit-audio__icon {
      opacity: 0.4; }
  .lit-audio:active .lit-audio__icon {
    -webkit-transition: none;
    transition: none;
    opacity: 0.7; }
  .lit-audio:active .lit-audio__outline {
    -webkit-transition: none;
    transition: none;
    opacity: 0.7; }

.TestComplete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .TestComplete h2 {
    max-width: 800px;
    text-align: center; }
  .TestComplete p {
    text-align: center;
    max-width: 700px; }

.connectionStatus {
  position: fixed;
  right: 10px;
  bottom: 10px;
  padding: 10px 10px;
  border-radius: 10px;
  background-color: #ffffffcc;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }
  .connectionStatus:hover {
    background-color: #ffffff; }
  .connectionStatus__device {
    width: 20px;
    margin: 0 5px; }
  .connectionStatus__icon {
    max-width: 15px; }

.progress {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .progress__test {
    flex-basis: 1;
    border: solid 3px #fff;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px; }
  .progress__line {
    border-bottom: solid 3px #fff;
    flex: 1 1;
    height: 1px;
    margin: 0px 7px; }
  .progress .completed {
    color: lime;
    border-color: lime; }
  .progress .current {
    color: #ffff46;
    border-color: #ffff46;
    box-shadow: 0 0 13px 3px #eba40c; }
  .progress .taken {
    color: #03121a;
    border-color: #b9ec96;
    background-color: #7df802; }
  .progress .currentAndTaken {
    color: #094386;
    font-weight: bold;
    border-color: #eaece9;
    background-color: #7df802;
    box-shadow: 0 0 13px 3px #eba40c; }

.subtest-complete-notice__left0 {
  z-index: 2000;
  position: -webkit-sticky;
  position: sticky;
  top: 20;
  font-size: 15px;
  border-left: solid 2px #7df802;
  border-bottom: solid 2px #7df802;
  border-bottom-right-radius: 5px;
  padding-left: 3px;
  color: #7df802;
  justify-content: left;
  align-items: left;
  margin-left: 1%; }

.subtest-complete-notice__left1 {
  z-index: 2000;
  position: -webkit-sticky;
  position: sticky;
  top: 20;
  font-size: 15px;
  border-left: solid 2px #7df802;
  border-bottom: solid 2px #7df802;
  border-bottom-right-radius: 5px;
  padding-left: 3px;
  color: #7df802;
  justify-content: left;
  align-items: left;
  margin-left: 25.5%; }

.subtest-complete-notice__left2 {
  z-index: 2000;
  position: -webkit-sticky;
  position: sticky;
  top: 20;
  font-size: 15px;
  border-left: solid 2px #7df802;
  border-bottom: solid 2px #7df802;
  border-bottom-right-radius: 5px;
  padding-left: 3px;
  color: #7df802;
  justify-content: left;
  align-items: left;
  margin-left: 50%; }

.subtest-complete-notice__left3 {
  z-index: 2000;
  position: -webkit-sticky;
  position: sticky;
  top: 20;
  font-size: 15px;
  border-left: solid 2px #7df802;
  border-bottom: solid 2px #7df802;
  border-bottom-right-radius: 5px;
  padding-left: 3px;
  color: #7df802;
  justify-content: left;
  align-items: left;
  margin-left: 74.5%; }

.TestAdmin {
  padding: 20px; }
  .TestAdmin__header {
    display: flex;
    justify-content: space-between; }
    .TestAdmin__header h1,
    .TestAdmin__header p {
      margin-bottom: 10px; }
  .TestAdmin .roomkey {
    margin-top: 0; }
  @media screen and (max-width: 1440px) {
    .TestAdmin .adminItem {
      padding: 10px 10px; }
      .TestAdmin .adminItem img {
        width: 90px; } }
  @media screen and (max-width: 1100px) {
    .TestAdmin .adminItem {
      padding: 10px 15px; }
      .TestAdmin .adminItem img {
        width: 70px; } }
  @media screen and (max-width: 800px) {
    .TestAdmin .adminItem {
      padding: 10px 5px; }
      .TestAdmin .adminItem img {
        width: 70px; } }
  .TestAdmin .adminItem {
    display: flex;
    flex-direction: column;
    line-height: 1;
    padding: 10px;
    font-size: 14px;
    margin: 0;
    color: #999; }
    .TestAdmin .adminItem img {
      width: 150px; }
  @media screen and (max-width: 1440px) {
    .TestAdmin .adminItem {
      padding: 10px 0px; }
      .TestAdmin .adminItem img {
        width: 125px; } }
  @media screen and (max-width: 1100px) {
    .TestAdmin .adminItem {
      padding: 10px 0px; }
      .TestAdmin .adminItem img {
        width: 90px; } }
  @media screen and (max-width: 800px) {
    .TestAdmin {
      padding: 0 10px; }
      .TestAdmin .adminItem {
        padding: 10px 0px; }
        .TestAdmin .adminItem img {
          width: 80px; } }

.ReadyButton {
  padding: 20px 40px;
  width: 200px;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  background-color: #46b6e5;
  border-radius: 5px; }
  .ReadyButton:hover {
    cursor: pointer;
    background-color: #52cbff; }
  .ReadyButton.ready {
    color: #ddd;
    background-color: #85c3dd; }
    .ReadyButton.ready:hover {
      background-color: #8fcfeb; }

.WorkingMemory {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .WorkingMemory .AudioButton {
    position: absolute;
    bottom: 20px;
    right: 20px; }

.RapidNamingIntro {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .RapidNamingIntro .items {
    display: flex;
    padding: 40px;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px; }
    .RapidNamingIntro .items .image {
      width: 100px;
      height: 100px;
      margin: 20px; }
    .RapidNamingIntro .items .circle {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin: 20px; }
      .RapidNamingIntro .items .circle.red {
        background-color: #ff0000; }
      .RapidNamingIntro .items .circle.green {
        background-color: #00ff00; }
      .RapidNamingIntro .items .circle.blue {
        background-color: #0000ff; }
      .RapidNamingIntro .items .circle.orange {
        background-color: #ff8a00; }
      .RapidNamingIntro .items .circle.gray {
        background-color: #888888; }

.ScrollingTest {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ScrollingTest .row {
    display: flex;
    background-color: white;
    margin: 10px;
    padding: 0 20px;
    border-radius: 10px; }
    .ScrollingTest .row.previous, .ScrollingTest .row.next {
      background-color: transparent;
      font-size: 12px;
      padding: 0px; }
      .ScrollingTest .row.previous .item, .ScrollingTest .row.next .item {
        color: #999; }
        .ScrollingTest .row.previous .item img, .ScrollingTest .row.next .item img {
          width: 100px;
          opacity: 0.3; }
      @media screen and (max-width: 1440px) {
        .ScrollingTest .row.previous .item, .ScrollingTest .row.next .item {
          padding: 10px 10px; }
          .ScrollingTest .row.previous .item img, .ScrollingTest .row.next .item img {
            width: 90px; } }
      @media screen and (max-width: 1100px) {
        .ScrollingTest .row.previous .item, .ScrollingTest .row.next .item {
          padding: 10px 15px; }
          .ScrollingTest .row.previous .item img, .ScrollingTest .row.next .item img {
            width: 70px; } }
      @media screen and (max-width: 800px) {
        .ScrollingTest .row.previous .item, .ScrollingTest .row.next .item {
          padding: 10px 5px; }
          .ScrollingTest .row.previous .item img, .ScrollingTest .row.next .item img {
            width: 70px; } }
    .ScrollingTest .row .item {
      display: flex;
      flex-direction: column;
      line-height: 1;
      padding: 10px;
      font-size: 14px;
      margin: 0;
      color: #999; }
      .ScrollingTest .row .item img {
        width: 150px; }
    @media screen and (max-width: 1440px) {
      .ScrollingTest .row .item {
        padding: 10px 0px; }
        .ScrollingTest .row .item img {
          width: 125px; } }
    @media screen and (max-width: 1100px) {
      .ScrollingTest .row .item {
        padding: 10px 0px; }
        .ScrollingTest .row .item img {
          width: 90px; } }
    @media screen and (max-width: 800px) {
      .ScrollingTest .row {
        padding: 0 10px; }
        .ScrollingTest .row .item {
          padding: 10px 0px; }
          .ScrollingTest .row .item img {
            width: 80px; } }

.PhonemeIsolation {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column; }
  .PhonemeIsolation p {
    max-width: 70%; }
  .PhonemeIsolation .phoneme-options {
    display: flex;
    flex-wrap: wrap; }
  .PhonemeIsolation .phoneme-option {
    min-width: 200px;
    flex-basis: 50%;
    box-sizing: border-box;
    display: flex;
    border: solid 1px black;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white; }

.PhonemeDeletion {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .PhonemeDeletion .AudioButton {
    position: absolute;
    bottom: 20px;
    right: 20px; }

.lit-stop-watch-div {
  display: none; }

.lit-count-down-div {
  margin: -22px 0px 0px 0px;
  position: relative;
  top: 18px; }

.lit-correct-button-div {
  margin: 0px !important; }

.lit-all-buttons-div {
  display: flex; }

.ScrollingTest {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .ScrollingTest .TestWrapper {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .ScrollingTest .TestWrapper .column {
      width: 300px;
      font-size: 35px;
      text-align: center;
      display: flex;
      flex-direction: column; }
      .ScrollingTest .TestWrapper .column.previous, .ScrollingTest .TestWrapper .column.next {
        color: #ccc;
        font-size: 25px;
        opacity: 0.1; }
      .ScrollingTest .TestWrapper .column .item {
        padding: 5px;
        margin: 0; }
      @media screen and (max-width: 1100px) {
        .ScrollingTest .TestWrapper .column {
          font-size: 30px; }
          .ScrollingTest .TestWrapper .column.previous, .ScrollingTest .TestWrapper .column.next {
            font-size: 20px; }
          .ScrollingTest .TestWrapper .column .item {
            padding: 2px;
            margin: 0; } }

@media screen and (max-height: 800px) {
  .TestWrapper .column {
    font-size: 18px !important; } }

.oneminute-admin .admin-inline {
  display: flex;
  justify-content: space-between;
  max-width: 790px; }
  .oneminute-admin .admin-inline p {
    margin: 0; }

.oneminute-admin .TestWrapper {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .oneminute-admin .TestWrapper .column {
    width: 200px;
    font-size: 22px;
    text-align: center;
    display: flex;
    flex-direction: column; }
    .oneminute-admin .TestWrapper .column.previous, .oneminute-admin .TestWrapper .column.next {
      color: #ccc;
      font-size: 16px;
      opacity: 0.1; }
    .oneminute-admin .TestWrapper .column .item {
      padding: 0;
      margin: 0; }
    .oneminute-admin .TestWrapper .column .correct {
      color: lime; }
    .oneminute-admin .TestWrapper .column .error {
      color: red; }
    .oneminute-admin .TestWrapper .column .active {
      background-color: white;
      border-radius: 5px;
      color: black; }

.LetterKnowledge {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .LetterKnowledge .letter {
    margin-top: 100px;
    background-color: #0e1e89;
    padding: 20px 40px;
    min-width: 100px;
    font-size: 120px;
    border-radius: 15px; }
  .LetterKnowledge .row {
    display: flex;
    font-size: 45px;
    background-color: #0e1e89;
    border-radius: 10px;
    padding: 10px; }
    .LetterKnowledge .row.previous, .LetterKnowledge .row.next {
      border-radius: 0px;
      padding: 0px;
      background-color: transparent;
      color: #ccc;
      font-size: 22px;
      -webkit-filter: brightness(0.9);
              filter: brightness(0.9); }
      .LetterKnowledge .row.previous .item, .LetterKnowledge .row.next .item {
        padding: 10px; }
    .LetterKnowledge .row .item {
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0; }

.lit-diktat {
  margin-top: 20px; }
  .lit-diktat .lit-input--disabled {
    opacity: 0.5; }

.WordDictation .lit-intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }

.Spoonerism {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .Spoonerism .AudioButton {
    position: absolute;
    bottom: 20px;
    right: 20px; }
  .Spoonerism .ReadyButton {
    margin-top: 15px; }
  .Spoonerism .spoonerism-examples .example {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Spoonerism .spoonerism-examples .example .example-box {
      padding: 10px;
      background-color: white;
      border-radius: 5px;
      color: black;
      margin: 5px 10px; }
    .Spoonerism .spoonerism-examples .example .arrow {
      width: 50px; }

.lit-stop-watch-div {
  display: none; }

.lit-count-down-div {
  margin: -22px 0px 0px 0px;
  position: relative;
  top: 18px; }

.lit-correct-button-div {
  margin: 0px !important; }

.lit-all-buttons-div {
  display: flex; }

.TestUserIntro {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1; }

.TestUser {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1 1; }
  .TestUser .testKey {
    margin: 0; }
  .TestUser .test {
    text-align: center; }
  .TestUser .lit-page-title {
    text-align: center; }


.SelectRole {
  text-align: center;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; }
  .SelectRole .SelectRoleContainer {
    padding-bottom: 20%; }
    .SelectRole .SelectRoleContainer form {
      display: inline-block; }
      .SelectRole .SelectRoleContainer form button:not(:first-child) {
        margin-left: 2; }
  .SelectRole .title {
    margin: 0;
    letter-spacing: 2px; }
    .SelectRole .title__logo {
      margin-top: 10px; }
  .SelectRole__heading {
    margin-top: 20px;
    margin-bottom: 0; }
  .SelectRole__label {
    text-align: center; }
    .SelectRole__label label {
      font-size: 23px; }
  .SelectRole__input {
    min-width: 230px;
    text-transform: uppercase; }
  .SelectRole__pinInput {
    min-width: 80px;
    text-align: center;
    color: blue;
    font-size: 18px;
    background-color: #d4d1d1; }
  .SelectRole__pinInput:focus {
    background-color: #f2f8fa; }
  .SelectRole__submit-button {
    margin-top: 20px;
    min-width: 20%; }
  .SelectRole__select-button:not(:first-child) {
    margin-left: 10px; }

.lit-thirdPCsupport {
  margin: auto;
  text-align: left;
  color: red;
  width: 70%;
  font-size: 18px; }

.lit-thrid-party-settings {
  margin: auto;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 20px; }

.environment-banner {
  height: 35px;
  background-color: #edac73;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 18px; }
  .environment-banner.development {
    background-color: #ed7373;
    display: flex; }
  .environment-banner.testing {
    background-color: #edac73;
    display: flex; }

