.ReadyButton {
	padding: 20px 40px;
	width: 200px;
	font-size: 25px;
	text-transform: uppercase;
	text-align: center;
	background-color: #46b6e5;
	border-radius: 5px;

	&:hover {
		cursor: pointer;
		background-color: #52cbff;
	}

	&.ready {
		color: #ddd;
		background-color: #85c3dd;
		&:hover {
			background-color: #8fcfeb;
		}
	}
}
