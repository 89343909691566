.lit-countdown {
	animation: fade 0.8s;

	&__progress {
		stroke: red;
		stroke-dasharray: 230px;
		stroke-dashoffset: 0;
	}
	.lit-circle__text {
		font-size: 0.6em;
		margin: 0;
		font-weight: bold;
	}
}
