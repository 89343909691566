.ScrollingTest {
	display: flex;
	flex-direction: column;
	align-items: center;

	.TestWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;
		.column {
			width: 300px;
			font-size: 35px;
			text-align: center;
			display: flex;
			flex-direction: column;

			&.previous,
			&.next {
				color: #ccc;
				font-size: 25px;
				opacity: 0.1;
			}

			.item {
				padding: 5px;
				margin: 0;
			}

			@media screen and (max-width: 1100px) {
				font-size: 30px;

				&.previous,
				&.next {
					font-size: 20px;
				}

				.item {
					padding: 2px;
					margin: 0;
				}
			}
		}
	}
}

@media screen and (max-height: 800px) {
	.TestWrapper {
		.column {
			font-size: 18px !important;
		}
	}
}

.oneminute-admin {
	.admin-inline {
		display: flex;
		justify-content: space-between;
		max-width: 790px;
		p {
			margin: 0;
		}
	}

	.TestWrapper {
		display: flex;
		flex-direction: row;
		align-items: center;

		.column {
			width: 200px;
			font-size: 22px;
			text-align: center;
			display: flex;
			flex-direction: column;

			&.previous,
			&.next {
				color: #ccc;
				font-size: 16px;
				opacity: 0.1;
			}

			.item {
				padding: 0;
				margin: 0;
			}

			.correct {
				color: lime;
			}
			.error {
				color: red;
			}

			.active {
				background-color: white;
				border-radius: 5px;
				color: black;
			}
		}
	}
}
