.TestAdmin {
	padding: 20px;

	&__header {
		display: flex;
		justify-content: space-between;

		h1,
		p {
			margin-bottom: 10px;
		}
	}

	.roomkey {
		margin-top: 0;
	}

	@media screen and (max-width: 1440px) {
		.adminItem {
			img {
				width: 90px;
			}
			padding: 10px 10px;
		}
	}
	@media screen and (max-width: 1100px) {
		.adminItem {
			img {
				width: 70px;
			}
			padding: 10px 15px;
		}
	}
	@media screen and (max-width: 800px) {
		.adminItem {
			img {
				width: 70px;
			}
			padding: 10px 5px;
		}
	}
	.adminItem {
		display: flex;
		flex-direction: column;
		line-height: 1;
		padding: 10px;
		font-size: 14px;
		margin: 0;
		color: #999;
		&.active {
			// background-color: #1b2e79;
		}

		img {
			width: 150px;
		}
	}
	@media screen and (max-width: 1440px) {
		.adminItem {
			img {
				width: 125px;
			}
			padding: 10px 0px;
		}
	}
	@media screen and (max-width: 1100px) {
		.adminItem {
			img {
				width: 90px;
			}
			padding: 10px 0px;
		}
	}
	@media screen and (max-width: 800px) {
		.adminItem {
			img {
				width: 80px;
			}
			padding: 10px 0px;
		}
		padding: 0 10px;
	}
}
