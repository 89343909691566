@import './styles/circle.scss';

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	p {
		max-width: 800px;
	}

	p.warning {
		margin-bottom: 5px;
		margin-top: 5px;
		color: rgb(255, 223, 78);
		font-size: 18px;
	}

	label {
		display: block;
		font-size: 18px;
		input[type='text'],
		input[type='number'],
		input[type='date'],
		select {
			display: block;
			margin-top: 5px;
			margin-bottom: 20px;
		}
		&.inline {
			display: inline-block;

			margin-right: 5px;
		}
	}

	select {
		// display: block;
		border: solid 1px #aaa;
		font-size: 16px;
		padding: 10px;
		border-radius: 5px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	input {
		// display: block;
		border: solid 1px #aaa;
		font-size: 16px;
		padding: 10px;
		border-radius: 5px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.checkbox-group-title {
		font-size: 20px;
		margin-top: 20px;
	}

	.info-box {
		background-color: #ffffff;
		padding: 20px;
		color: rgb(20, 20, 20);
		font-size: 18px;
		border-radius: 10px;
		display: inline-block;

		&__title {
			margin-top: 0px;
			margin-bottom: 5px;
			font-size: 20px;
			font-weight: bold;
		}

		&__hint {
			font-size: 18px;
			font-style: italic;
			color: #777;
			margin: 0;
		}

		ol {
			padding-left: 20px;
			margin-bottom: 0;
		}
	}

	.center {
		text-align: center;
	}
}
