.TestComplete {
	h2 {
		max-width: 800px;
		text-align: center;
	}
	p {
		text-align: center;
		max-width: 700px;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
