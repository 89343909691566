.WorkingMemory {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	.AudioButton {
		position: absolute;
		bottom: 20px;
		right: 20px;
	}
}
